<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Artigos</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Artigos</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'articlesCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> Cadastrar artigo
                        </router-link>

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input
                                 type="text"
                                 name="table_search"
                                 class="form-control float-right"
                                 placeholder="Buscar"
                                 v-model="searchQuery"
                                 @input="handleDelayedSearch">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="(isLoading || allCategories.length == 0) " class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>Título</th>
                              <th>Data de criação</th>
                              <th>Ações/Categorias</th>
                              <th>Estado</th>
                              <th>Restrição</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(article, index) in articles" :key="index">
                                 <td>
                                    <div class="row justify-content-between" style="width: 450px;">
                                       <div class="col-4">
                                          <div class="timeline-item">
                                             <div class="timeline-body">
                                                <img
                                                   :src="article.image !== '' ? article.image : 'https://placehold.it/150x100'"
                                                   width="150"
                                                   height="100"
                                                   alt="..."
                                                >
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-8">
                                          <p
                                             class="h5 pl-2 wrap-text text-secondary text-bold font-italic"
                                             style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                                          >
                                             {{ article.title }}
                                          </p>
                                          <span class="text-gray pl-2">
                                             {{ article.author }} | <i class="fas fa-comments"></i>{{ article.numberOfComments }}
                                          </span>
                                       </div>
                                    </div>
                                 </td>
                                 <td>{{ article.createdAt }}</td>
                                 <td>
                                    <div style="width: 130px;">
                                       <div class="row">
                                          <router-link :to="{ name: 'articlesEdit', params: { uuid: article.uuid } }">
                                             <div class="row text-primary mr-4">
                                                <i class="pt-1 fas fa-pencil-alt"></i> <p class="ml-1 text-bold">
                                                {{ $t('edit') }}</p>
                                             </div>
                                          </router-link>
                                          <div
                                             @click.prevent="showConfirmationModal(article)"
                                             class="row text-danger"
                                             style="cursor: pointer"
                                          >
                                             <i class="pt-1 fas fa-trash-alt"></i> <p class="ml-1 text-bold"> {{ $t('exclude') }} </p>
                                          </div>
                                       </div>
                                       <p
                                          class="text-gray text-bold"
                                          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                                       >
                                          {{ getCategories(article.categories) }}
                                       </p>
                                    </div>
                                 </td>
                                 <td>
                                    <button
                                       :class="['btn btn-sm', article.status === 'published' ? 'btn-outline-primary' : 'btn-outline-warning']"
                                       @click.prevent="updateStatus(article.status, article.uuid, index)"
                                       :disabled="isSavingStatus"
                                    >
                                       {{ $t(`${article.status}`) }}
                                    </button>
                                 </td>
                                 <td>
                                    <button
                                       :class="['btn btn-sm', article.subscriptionType === 'free' ? 'btn-outline-success' : 'btn-outline-danger']"
                                       @click.prevent="updateSubscriptionType(article.subscriptionType, article.uuid, index)"
                                       :disabled="isSavingSubscriptionType"
                                    >
                                       <i v-if="article.subscriptionType === 'free'" class="fas fa-unlock"></i>
                                       <i v-else class="fas fa-lock"></i>
                                       {{$t(`${article.subscriptionType}`) }}
                                    </button>
                                 </td>
                                 <ConfirmationModal
                                    :modalId="modalConfirmationActionId"
                                    @actionConfirmed="deleteArticle"
                                    @actionCancelled="$(`#${modalConfirmationActionId}`).modal('hide');"
                                 />
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import articleService from "../services/article-service"
import ConfirmationModal from "../../../components/ConfirmationModal.vue"
import Pagination from "../../../components/Pagination.vue";
import { useToast } from "vue-toastification"
import { mapState, mapActions, mapMutations } from "vuex"

export default {
   name: "Articles",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      ConfirmationModal,
      Pagination
   },
   data () {
      return {
         articles: null,
         articleSelected: null,
         isLoading: false,
         isLoadingPagination: false,
         isSavingStatus: false,
         isSavingSubscriptionType: false,
         searchTimeout: null,
         searchQuery: "",
         modalConfirmationActionId: "modal-confirmation-action-article",
         adjacentPagesCount: 2,
         isSearching: false
      }
   },
   computed: {
      ...mapState({
         allCategories: state => state.category.allCategories,
         currentPage: state => state.article.articles.pagination.currentPage,
         tokenPages: state => state.article.articles.pagination.tokenPages,
         totalPages: state => state.article.articles.pagination.totalPages,
         allArticlesCategoriesRelation: state => state.article.articles.allArticlesCategoriesRelation
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   async created() {
      this.setCurrentPage(1);
      this.isLoading = true;
      this.isLoadingPagination = true;

      try {
         const { data: response }  = await articleService.getAllPaginated();
         this.articles = response;
         this.isLoading = false;

         if (!this.tokenPages.length){
            const { data: paginationInfo } = await articleService.getPaginationInfo();
            this.setPaginationInfo({ totalPages: paginationInfo.totalPages, data: paginationInfo.data, tokenPages: paginationInfo.tokens });
         }
      } finally {
         this.isLoadingPagination = false;
      }
   },
   methods: {
      ...mapActions(['getAllArticlesCategoriesRelation']),
      ...mapMutations({
         setCurrentPage: 'SET_ARTICLE_CURRENT_PAGE',
         setPaginationInfo: 'SET_ARTICLE_PAGINATION_INFO'
      }),
      async deleteArticle() {
         try {
            await this.getAllArticlesCategoriesRelation();

            await articleService.destroy(this.articleSelected.uuid, this.allArticlesCategoriesRelation, this.articleSelected.image);

            $(`#${this.modalConfirmationActionId}`).modal('hide');

            const { data: articles } = await articleService.getAllPaginated();
            this.articles = articles;

            this.toast.success(this.$t("excluded"));

            const { data: paginationInfo } = await articleService.getPaginationInfo();
            this.setPaginationInfo({ totalPages: paginationInfo.totalPages, data: paginationInfo.data, tokenPages: paginationInfo.tokens });
         } catch (error) {
            $(`#${this.modalConfirmationActionId}`).modal('hide');
            this.toast.error(this.$t("exclude_failure"));
         }
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);
         this.isLoading = true;

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               const response = await articleService.search(this.searchQuery);
               this.articles = response;
               this.isLoading = false;
            } else {
               this.isSearching = false;
               const { data: response }  = await articleService.getAllPaginated();
               this.articles = response;
               this.setCurrentPage(1);
               this.isLoading = false;
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.isLoading = true;
               this.setCurrentPage(targetPage.page);
               const { data: response } = await articleService.getAllPaginated(targetPage.token);
               this.articles = response;
               this.isLoading = false;
            }
         }
      },
      getCategories (categoryUuidArray) {
         let arrayOfCats = [];
         this.allCategories.map(item => {

            categoryUuidArray.map(catUuid => {
               if (item.uuid === catUuid)
                  arrayOfCats.push(item.name);
            });
         });

         return arrayOfCats.join(', ');
      },
      async updateStatus (status, uuid, idx) {
         this.isSavingStatus = true;

         if (status === 'draft')
            status = 'published';
         else
            status = 'draft';

         try {
            this.articles[idx].status = 'updating';
            await articleService.editStatus(status, uuid);

            const { data: articles } = await articleService.getAllPaginated();
            this.articles = articles;

            this.toast.success(this.$t("saved"));
         } catch (e) {
            this.toast.error(this.$t("save_failure"));
         } finally {
            this.isSavingStatus = false;
            this.articles[idx].status = status;
         }
      },
      async updateSubscriptionType (subscriptionType, uuid, idx) {
         this.isSavingSubscriptionType = true;

         if (subscriptionType === 'free')
            subscriptionType = 'paid';
         else
            subscriptionType = 'free';

         try {
            this.articles[idx].subscriptionType = 'updating';
            await articleService.editSubscriptionType(subscriptionType, uuid);

            const { data: articles } = await articleService.getAllPaginated();
            this.articles = articles;

            this.toast.success(this.$t("saved"));
         } catch (e) {
            this.toast.error(this.$t("save_failure"));
         } finally {
            this.isSavingSubscriptionType = false;
            this.articles[idx].subscriptionType = subscriptionType;
         }
      },
      showConfirmationModal(item) {
         this.articleSelected = item
         $(`#${this.modalConfirmationActionId}`).modal('show')
      }
   }
}
</script>

